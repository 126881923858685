import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/helmets/Seo";
import styled from "styled-components";
import { HeadlineBlock } from "../components/articles/HeadlineBlock";
import { Box, Typography, Chip, Grid } from "@material-ui/core";
import { AstRenderer } from "../components/articles/AstRenderer";
import "../styles/article.css";
import { KnowledgeLayout } from "../components/knowledges/KnowledgeLayout";
import { ShareBelt } from "../components/social/ShareBelt";

const StyledArticleBox = styled.article`
  max-width: 960px;
`;

const StyledChip = styled(Chip)`
  padding-left: 4px;
  padding-right: 4px;
`;

const Knowledge = ({data})=>{
  const markdownRemark = data.markdownRemark;
  const treeTarget = markdownRemark.fields.slug;
  const categories: string[] = markdownRemark.frontmatter.category.split("/");

  return(
    <KnowledgeLayout selectionTarget={treeTarget} categories={categories}>
      <Seo
        title={markdownRemark.frontmatter.title}
        article
        description={markdownRemark.excerpt}
        image="/images/knowledge.png"
      />
      <StyledArticleBox>
        <HeadlineBlock title={markdownRemark.frontmatter.title} my={0}>
          <Box mt={1} mb={2}>
            <Typography variant="body2" component="span" color="textSecondary">
              {markdownRemark.frontmatter.date}
            </Typography>
          </Box>
          <Box my={1}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              {markdownRemark.frontmatter.keywords.map(word =>(
                <Grid item key={word}>
                  <StyledChip variant="outlined" size="small" label={word} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </HeadlineBlock>
        <Box my={2}>
          <AstRenderer htmlAst={markdownRemark.htmlAst} />
        </Box>
        <Box my={4}>
          <ShareBelt
            title={markdownRemark.frontmatter.title}
            description={markdownRemark.frontmatter.description}
            path={`/knowledges${markdownRemark.fields.slug}`}
          />
        </Box>
      </StyledArticleBox>
    </KnowledgeLayout>
  );
}


export const pageQuery = graphql`
  query KnowledgeBySlug($slug: String) {
    markdownRemark(
      fileAbsolutePath: {regex: "/knowledges//"},
      fields: { slug: { eq: $slug } }
    ){
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
        keywords
        category
      }
      excerpt(pruneLength: 160)
      htmlAst
      fields {
        slug
      }
    }
  }
`;

export default Knowledge;